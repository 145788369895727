<template>
  <div class="error" v-if="error">{{ error }}</div>

  <Loader v-if="isLoading" />
  <div class="page-loader" v-if="pageLocked">
    <Loader />
  </div>

  <div v-if="!error">
    <div v-if="list.visibility != 0" v-on:click="share" class="share"></div>
    <div class="toast" v-if="showToast">URL copied to clipboard.</div>

    <h2>{{ list.title }}</h2>

    <div v-for="(category, catIndex) in categories" v-bind:key="catIndex">
      <div v-if="category">
        <h3 v-on="editMode ? { click: () => editCategory(category, catIndex) } : {}" v-bind:class="[editMode ? 'clickable' : '']">{{ category.title }}</h3>
        <div class="section">
          <div class="grid">
            <div class="item" v-for="(record, index) in category.items" v-bind:key="record.id" v-bind:class="getItemClass(index, category.items.length)">
              <div class="select" v-on:click="itemClicked(record, category, catIndex)" v-bind:class="[getColorClass(index, category.id), {complete: record.completed == 1}]">{{ record.title }}</div>
            </div>
            <div class="item" v-if="editMode"><div class="add-item" v-on:click="newItem(category, catIndex)">Add Item +</div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="add-category" v-if="isLoggedIn && editMode" v-on:click="categoryModal">Add Category +</div>
    <div v-if="isLoggedIn && canEdit" id="edit-mode">
      <div>Edit Mode</div>
      <input type="checkbox" v-model="editMode" id="toggle"><label for="toggle">Toggle</label>
    </div>

    <div class="modal" v-bind:class="[modal == 'add item' ? 'show' : '']">
      <div class="modal-content">
        <input type="text" v-model="newItemTitle" v-on:keyup.enter="addItem" ref="newtitle" placeholder="Enter Title">
        <Loader v-if="isLoading" />
        <div v-if="!isLoading">
          <button class="btn" v-on:click="addItem">Add Item</button> or
          <span class="action" v-on:click="closeModal">Cancel</span>
        </div>
      </div>
    </div>
    <div class="modal" v-bind:class="[modal == 'edit item' ? 'show' : '']">
      <div class="modal-content">
        <input type="text" v-model="editTitle" v-on:keyup.enter="updateItem" ref="edittitle" placeholder="Enter Title">
        <Loader v-if="isLoading" />
        <div v-if="!isLoading">
          <button class="btn" v-on:click="updateItem">Update</button>
          <button class="btn-secondary" v-on:click="deleteItem">Delete</button> or
          <span class="action" v-on:click="closeModal">Cancel</span>
        </div>
      </div>
    </div>
    <div class="modal" v-bind:class="[modal == 'add category' ? 'show' : '']">
      <div class="modal-content">
        <input type="text" v-model="newCategoryTitle" v-on:keyup.enter="addCategory" ref="newcategorytitle" placeholder="Enter Title">
        <Loader v-if="isLoading" />
        <div v-if="!isLoading">
          <button class="btn" v-on:click="addCategory">Add Category</button> or
          <span class="action" v-on:click="closeModal">Cancel</span>
        </div>
      </div>
    </div>
    <div class="modal" v-bind:class="[modal == 'edit category' ? 'show' : '']">
      <div class="modal-content">
        <input type="text" v-model="editTitle" v-on:keyup.enter="updateCategory" ref="editcategorytitle" placeholder="Enter Title">
        <Loader v-if="isLoading" />
        <div v-if="!isLoading">
          <button class="btn" v-on:click="updateCategory">Update</button>
          <button class="btn-secondary" v-on:click="deleteCategory">Delete</button> or
          <span class="action" v-on:click="closeModal">Cancel</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import axios from 'axios'
import authHeader from '../services/auth-header'
import store from '../store.js'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

export default {
  name: 'ListView',
  components: {
    Loader
  },
  computed: {
    isLoggedIn () {
      return store.isLoggedIn
    }
  },
  data () {
    return {
      pageLocked: false,
      isLoading: true,
      showToast: false,
      error: '',
      canEdit: false,
      editMode: false,
      modal: '',
      activeCategory: '',
      activeCatIndex: '',
      newItemTitle: '',
      editItem: {},
      editTitle: '',
      newCategoryTitle: '',
      list: {},
      categories: {},
      completed: {}
    }
  },
  created () {
    this.getList(this.$route.params.username, this.$route.params.url)
  },
  methods: {
    updateCompletedLocal: function () {
      const completed = localStorage.getItem('completed_' + this.list.id) || '{}'
      this.completed = JSON.parse(completed)
      for (const categoryId in this.categories) {
        for (const id in this.categories[categoryId].items) {
          this.categories[categoryId].items[id].completed = this.completed[this.categories[categoryId].items[id].id] || 0
        }
      }
    },
    updateTheme: function () {
      var url, bodyClass
      if (this.list.theme === 'holiday') {
        url = 'https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap'
        bodyClass = 'holiday'
      } else if (this.list.theme === 'jingle') {
        bodyClass = 'jingle'
      } else if (this.list.theme === 'santa') {
        url = 'https://fonts.googleapis.com/css2?family=Galada&family=Great+Vibes&family=Metal&display=swap'
        bodyClass = 'santa'
      } else if (this.list.theme === 'spooky') {
        url = 'https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Great+Vibes&family=Neucha&display=swap'
        bodyClass = 'spooky'
      } else if (this.list.theme === 'dark') {
        bodyClass = 'dark'
      } else if (this.list.theme === 'fall') {
        url = 'https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap'
        bodyClass = 'fall'
      } else if (this.list.theme === 'usa') {
        bodyClass = 'usa'
      } else if (this.list.theme === 'sunset') {
        url = 'https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap'
        bodyClass = 'sunset'
      } else if (this.list.theme === 'mardigras') {
        url = 'https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap'
        bodyClass = 'mardigras'
      } else {
        bodyClass = 'default'
      }
      if (url) {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = url
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      if (bodyClass) {
        document.body.classList.add(bodyClass)
      }
    },
    getList: function (username, url) {
      axios
        .get(API_URL + '/users/' + username + '/lists/' + url, {
          headers: authHeader()
        })
        .then(response => {
          this.isLoading = false
          if (response.data.list) {
            this.list = response.data.list
            this.categories = response.data.list.categories
            this.canEdit = response.data.list._can_edit
            if (this.canEdit && this.categories.length === 0) {
              this.editMode = true
            }
            this.updateTheme()
            if (!this.isLoggedIn) {
              this.updateCompletedLocal()
            }
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === 403) {
            this.error = 'Beat it nerd! This is a private list.'
          } else {
            this.error = error
          }
        })
    },
    share: function () {
      const el = document.createElement('textarea')
      el.value = window.location.href
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      const that = this
      this.showToast = true
      setTimeout(function () {
        that.showToast = false
      }, 3000)
    },
    getItemClass: function (index, length) {
      var classes = []
      if (index % 2 === 0 && index === (length - 1)) {
        classes.push('mobile-full')
      }
      if (index % 3 === 0 && index === (length - 1)) {
        classes.push('desktop-full')
      } else if (length === 2 || (index % 3 === 0 && index === (length - 2)) || (index % 4 === 0 && index === (length - 1))) {
        classes.push('desktop-half')
      }
      return classes
    },
    getColorClass: function (start, offset) {
      var colorClasses = ['color-1', 'color-2', 'color-3', 'color-4', 'color-5', 'color-6']
      var index = (parseInt(start) + parseInt(offset)) % colorClasses.length
      return colorClasses[index]
    },
    itemClicked: function (item, category, catIndex) {
      const completed = parseInt(item.completed) === 1 ? 0 : 1
      if (this.editMode) {
        this.activeCategory = category
        this.activeCatIndex = catIndex
        this.editItem = item
        this.editTitle = item.title
        this.modal = 'edit item'
      } else {
        if (this.isLoggedIn) {
          this.pageLocked = true
          axios
            .put(API_URL + '/items/toggle/' + this.list.id + '/' + item.id, {
              list_id: this.list.id,
              completed: completed
            }, {
              headers: authHeader()
            })
            .then(response => {
              this.pageLocked = false
              var index = this.categories[catIndex].items.map(
                function (e) {
                  return e.id
                }).indexOf(item.id)
              this.categories[catIndex].items[index].completed = completed
            })
            .catch(error => {
              this.pageLocked = false
              console.log(error)
            })
        } else {
          item.completed = completed
          this.completed[item.id] = item.completed
          localStorage.setItem('completed_' + this.list.id, JSON.stringify(this.completed))
        }
      }
    },
    categoryModal: function () {
      this.modal = 'add category'
      this.$nextTick(function () {
        this.$refs.newcategorytitle.focus()
      }, this)
    },
    addCategory: function () {
      if (this.newCategoryTitle.length) {
        this.isLoading = true
        axios
          .post(API_URL + '/categories', {
            list_id: this.list.id,
            title: this.newCategoryTitle
          }, {
            headers: authHeader()
          })
          .then(response => {
            this.isLoading = false
            this.categories.push({
              id: response.data.id,
              title: response.data.title,
              items: []
            })
            this.newCategoryTitle = ''
            this.closeModal()
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    },
    editCategory: function (category, catIndex) {
      this.activeCatIndex = catIndex
      this.activeCategory = category
      this.editTitle = category.title
      this.modal = 'edit category'
    },
    updateCategory: function () {
      if (this.editTitle.length) {
        if (this.editTitle !== this.activeCategory.title) {
          this.isLoading = true
          axios
            .put(API_URL + '/categories/' + this.list.id + '/' + this.activeCategory.id, {
              title: this.editTitle
            }, {
              headers: authHeader()
            })
            .then(response => {
              this.isLoading = false
              this.categories[this.activeCatIndex].title = response.data.title
              this.editTitle = ''
              this.closeModal()
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)
            })
        }
      }
    },
    deleteCategory: function () {
      this.isLoading = true
      axios
        .delete(API_URL + '/categories/' + this.list.id + '/' + this.activeCategory.id, {
          headers: authHeader()
        })
        .then(response => {
          this.isLoading = false
          delete this.categories[this.activeCatIndex]
          this.activeCatIndex = ''
          this.editTitle = ''
          this.closeModal()
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    newItem: function (category, catIndex) {
      this.activeCategory = category
      this.activeCatIndex = catIndex
      this.modal = 'add item'
      this.$nextTick(function () {
        this.$refs.newtitle.focus()
      }, this)
    },
    closeModal: function () {
      this.modal = ''
    },
    addItem: function () {
      if (this.newItemTitle.length) {
        this.isLoading = true
        axios
          .post(API_URL + '/items', {
            list_id: this.list.id,
            category_id: this.activeCategory.id,
            title: this.newItemTitle
          }, {
            headers: authHeader()
          })
          .then(response => {
            this.isLoading = false
            this.categories[this.activeCatIndex].items.push({
              id: response.data.id,
              title: response.data.title,
              cat_id: this.activeCatIndex,
              cat_title: this.activeCategory.title,
              completed: 0
            })
            this.newItemTitle = ''
            this.closeModal()
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    },
    updateItem: function () {
      if (this.editTitle.length) {
        this.isLoading = true
        axios
          .put(API_URL + '/items/' + this.list.id + '/' + this.editItem.id, {
            title: this.editTitle
          }, {
            headers: authHeader()
          })
          .then(response => {
            this.isLoading = false
            var index = this.categories[this.activeCatIndex].items.map(
              function (e) {
                return e.id
              }).indexOf(response.data.id)
            this.categories[this.activeCatIndex].items[index].title = response.data.title
            this.editTitle = ''
            this.closeModal()
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    },
    deleteItem: function () {
      this.isLoading = true
      axios
        .delete(API_URL + '/items/' + this.list.id + '/' + this.editItem.id, {
          headers: authHeader()
        })
        .then(response => {
          this.isLoading = false
          var index = this.categories[this.activeCatIndex].items.map(
            function (e) {
              return e.id
            }).indexOf(this.editItem.id)
          this.categories[this.activeCatIndex].items.splice(index, 1)
          this.editTitle = ''
          this.closeModal()
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="sass">

h2
  margin-bottom: 15px
  @media only screen and (min-width: 600px)
    margin-bottom: 25px
    font-size: 68px

h3
  margin-bottom: 2px

.section
  position: relative
  border: 1px solid #eee
  border-radius: 8px
  padding: 5px
  margin-bottom: 40px
  @media only screen and (min-width: 600px)
    padding: 10px

.select,
.add-item,
.add-category
  display: flex
  align-items: center
  justify-content: center
  padding: 10px
  margin: 5px
  height: calc(100% - 10px)
  cursor: pointer
  background-color: #444
  color: #fff
  border-radius: 5px
  @media only screen and (min-width: 600px)
    height: calc(100% - 20px)
    padding: 13px
    margin: 10px
    font-size: 22px

.add-item,
.add-category
  background-color: #fff
  color: #000

.select
  background-color: #ff2768

.complete
  opacity: 0.5
  text-decoration: line-through
  background-color: #666
  color: #000

.grid
  display: flex
  flex-wrap: wrap

.item
  flex: 0 49.999%
  @media only screen and (min-width: 600px)
    flex: 0 33.333%
  &.mobile-full
    flex: 0 100%
    @media only screen and (min-width: 600px)
      flex: 0 33.333%
  &.desktop-half
    @media only screen and (min-width: 600px)
      flex: 0 49.999%
  &.desktop-full
    @media only screen and (min-width: 600px)
      flex: 0 100%

.modal
  display: none
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(0,0,0,0.95)
  text-align: center
  padding: 50px 20px
  color: #fff
  &.show
    display: flex
    align-items: center
    justify-content: center
  .modal-content
    width: 600px
    max-width: 600px
    margin: 0 auto
    input
      margin-bottom: 10px
    .btn,
    .btn-secondary
      font-size: 16px
      padding: 8px 12px
    .action
      color: rgba(255,255,255,0.8)
      cursor: pointer

#edit-mode
  margin-top: 60px
  color: #999
  text-align: center
  font-size: 14px
  input[type="checkbox"]
    height: 0
    width: 0
    visibility: hidden
    position: absolute
  label
    cursor: pointer
    text-indent: -9999px
    width: 60px
    height: 30px
    background: grey
    display: inline-block
    border-radius: 30px
    position: relative
    &:after
      content: ''
      position: absolute
      top: 2px
      left: 2px
      width: 26px
      height: 26px
      background: #fff
      border-radius: 26px
      transition: 0.3s
  input:checked + label
    background: #7fca42
    &:after
      left: calc(100% - 2px)
      transform: translateX(-100%)
  label:active:after
    width: 40px
.share
  height: 0
  padding-top: 24px
  width: 24px
  background-size: contain
  overflow: hidden
  text-indent: -9999px
  background: url('../assets/icon-share.svg') no-repeat
  position: absolute
  top: 58px
  left: 10px
  opacity: 0.5
  cursor: pointer
  @media only screen and (min-width: 600px)
    top: 65px
.toast
  position: absolute
  top: 58px
  left: 10px
  background-color: rgba(0,0,0,0.75)
  color: #fff
  font-weight: bold
  padding: 10px 15px
  border-radius: 5px
  @media only screen and (min-width: 600px)
    top: 65px

.default
  h2
    color: #fff
  h3
    font-size: 16px
    text-transform: uppercase
    letter-spacing: 3px
    color: rgba(255,255,255,0.8)
  .section
    border: 0
    background-color: rgba(255,255,255,0.75)
  #edit-mode
    color: #fff
    label
      background: rgba(255,255,255,0.5)
.dark
  h3
    font-size: 16px
    text-transform: uppercase
    letter-spacing: 3px
    color: rgba(255,255,255,0.5)
  .section
    border: 0
    background-color: #292929
  .select
    background-color: #bb86fc
    color: #000
  .complete
    opacity: 0.3
    background-color: #000
    color: #fff
    border: 1px solid #fff
  #edit-mode
    input:checked + label
      background: #03dac6
  .share
    background: url('../assets/icon-share-light.svg') no-repeat
.holiday
  h2
    font-family: 'Berkshire Swash', cursive
    color: #fff
  h3
    color: #fff
    text-align: left
    font-size: 17px
  .section
    border-color: #d24e4e
  .color-6,
  .color-4
    background-color: #329422
  .color-3,
  .color-5
    background-color: #126f02
  .color-2,
  .color-1
    background-color: #268516
  .complete
    opacity: 0.3
    background-color: #000
    color: #fff
  #edit-mode
    color: #fff
    label
      background: #aa8c8c
    input:checked + label
      background: #43c42e

.jingle
  h2
    color: #fff
  h3
    color: #fff
    text-align: left
    font-size: 12px
    letter-spacing: 4px
  .section
    border-color: #fff
    border-radius: 0
  .select
    border-radius: 0
  .add-item,
  .add-category
    border-radius: 0
    background-color: #fee934
    color: #a0240e
  .color-6,
  .color-4
    background-color: #dc0d17
  .color-3,
  .color-5
    background-color: #000
  .color-2,
  .color-1
    background-color: #fff
    color: #000
  .complete
    opacity: 0.3
    background-color: #000
    color: #fff
  #edit-mode
    color: #fff
    label
      background: #869a87
    input:checked + label
      background: #43c42e
  .share
    background: url('../assets/icon-share-light.svg') no-repeat

.santa
  h2
    color: #fca91b
    font-family: 'Great Vibes', cursive
    margin-bottom: 30px
    background: url('../assets/themes/santa/santa.png') top center no-repeat
    background-size: 150px
    padding-top: 100px
  h3
    color: #e85a58
    font-family: 'Galada', cursive
    margin-bottom: 10px
    @media only screen and (min-width: 600px)
      font-size: 32px
  .section
    border: 0
    border-radius: 0
    margin-bottom: 20px
  .item
    flex: 0 0 100%
  .select
    display: inline-flex
    background-color: transparent
    border-radius: 0
    font-size: 20px
    line-height: .9
    margin-top: 0
    margin-bottom: 0
    padding: 6px
    font-family: Metal, Helvetica, Arial, sans-serif
    @media only screen and (min-width: 600px)
      font-size: 26px
      line-height: 1
      padding: 10px
  .add-item,
  .add-category
    border: 1px solid #fff
    background-color: transparent
    color: #fff
  .complete
    opacity: 0.3
    color: #fff
  #edit-mode
    color: #fff
    label
      background: #869a87
    input:checked + label
      background: #43c42e
  .share
    background: url('../assets/icon-share-light.svg') no-repeat

.spooky
  .page
    font-family: 'Neucha', cursive
  .share
    background: url('../assets/icon-share-light.svg') no-repeat
  h2
    font-family: 'Caveat Brush', cursive
    color: #7fca42
    @media only screen and (min-width: 600px)
      font-size: 80px
  h3
    margin-bottom: 0
    font-family: 'Great Vibes', cursive
    color: #ccc
    font-size: 26px
    text-align: left
  .color-1
    background-color: #632452
    color: #fff
  .color-2
    background-color: #b2af87
  .color-3
    background-color: #a58799
  .color-4
    background-color: #7fca42
  .color-5
    background-color: #ff7c4b
  .color-6
    background-color: #fa5600
    color: #fff

.fall
  h2
    font-family: 'Great Vibes', cursive
    color: #9f2f1f
  h3
    font-family: 'Great Vibes', cursive
    margin-bottom: 0
    color: #ef9030
    font-size: 20px
    text-align: left
    @media only screen and (min-width: 600px)
      font-size: 26px
  .section
    border-color: #ef9030
  .color-6,
  .color-4
    background-color: #9f2f1f
  .color-3,
  .color-5
    background-color: #ef9030
  .color-2,
  .color-1
    background-color: #df6221
  .complete
    opacity: 0.4
    background-color: #988169
  #edit-mode
    color: #988169
    label
      background: #e4bd97
    input:checked + label
      background: #5ca456

.sunset
  h2
    font-family: 'Berkshire Swash', cursive
    color: #fff
  h3
    font-family: 'Berkshire Swash', cursive
    color: #fff
    text-align: left
    font-size: 17px
  .section
    border-color: #fff
    background-color: #fff
  .add-item,
  .add-category
    border: 1px solid #90669E
  .color-6,
  .color-4
    background-color: #F1B46C
  .color-3,
  .color-5
    background-color: #BC6798
  .color-2,
  .color-1
    background-color: #E58C79
  .complete
    opacity: 0.3
    color: #000
  #edit-mode
    color: #fff
    label
      background: rgba(255,255,255,0.5)
    input:checked + label
      background: #F1DC59

.usa
  h2
    color: #fff
  h3
    color: #fff
    font-size: 17px
  .section
    border-color: #fff
    background-color: #fff
  .add-item,
  .add-category
    border: 1px solid #90669E
  .color-1,
  .color-2,
  .color-3,
  .color-4,
  .color-5,
  .color-6
    background-color: #f13a3a
  .complete
    opacity: 0.3
    color: #000
  #edit-mode
    color: #fff
    label
      background: rgba(255,255,255,0.5)
    input:checked + label
      background: #ffdf00

.mardigras
  h2
    font-family: 'Nanum Pen Script', cursive
    color: #529834
    text-shadow: -2px -2px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
    font-size: 65px
    margin-bottom: 0
    line-height: .8
    background: url('../assets/themes/mardigras/crown.svg') no-repeat
    background-size: cover
    padding: 35px 45px 0 50px
    display: inline-block
  h3
    font-family: 'Nanum Pen Script', cursive
    text-transform: uppercase
    font-size: 42px
    display: flex
    align-items: center
    justify-content: center
    &::before,
    &::after
      content: ''
      display: inline-block
      background-size: cover
    &::before
      height: 22px
      width: 18px
      margin-right: 10px
      background: url('../assets/themes/mardigras/confetti-left.svg') no-repeat
    &::after
      height: 23px
      width: 27px
      margin-left: 10px
      background: url('../assets/themes/mardigras/confetti-right.svg') no-repeat
  .section
    border-color: #fada8c
    border-width: 10px
    background-color: #fff
    padding: 15px 5px
  .select
    font-family: 'Nanum Pen Script', cursive
    font-size: 27px
    line-height: .7
    margin-top: 0
    margin-bottom: 0
    padding: 6px
    background-color: #fff
    @media only screen and (min-width: 600px)
      font-size: 30px
      line-height: .8
      padding: 10px
  .item
    &:nth-child(4n+1),
    &:nth-child(4n+4)
      .select
        color: #4d4ead
    &:nth-child(4n+2),
    &:nth-child(4n+3)
      .select
        color: #529834
  .item
    &:nth-child(odd)
      .select
        @media only screen and (min-width: 600px)
          color: #4d4ead
    &:nth-child(even)
      .select
        @media only screen and (min-width: 600px)
          color: #529834
  .complete
    opacity: 0.3
  #edit-mode
    color: #fff
    label
      background: #9494ce
  .add-item
    border: 1px solid #fada8c
</style>

<style lang="sass">
.dark
  background: #090f20
  color: #fff
.fall
  background: #fbeee1
.sunset
  background: #90669E
.usa
  background: #1463b8
  .page-toolbar
    background-color: #f13a3a
.holiday
  background: #ad0505
.jingle
  background: #0b6817
  text-transform: uppercase
  font-family: Exo, Helvetica, Arial, sans-serif
.santa
  background: linear-gradient(180deg, #1c2f59, #62767d)
  .page
    background: url('../assets/themes/santa/cabin.png') bottom left no-repeat
    background-size: contain
    padding-bottom: 34%
    @media only screen and (min-width: 600px)
      background-size: 500px
      padding-bottom: 100px
.spooky
  background: #0c1514
  color: #fff
  .page-toolbar
    background-color: #fa5600
.mardigras
  background: #4e4fae
  color: #fff
</style>
